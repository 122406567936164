import React, { Fragment } from 'react';
import Intro from './Intro';
import MediaLibrary from '../common/MediaLibrary';
import CardList from '../common/CardList';
import Pricing from '../common/Pricing';
import CTA from '@components/CallToAction';
import Separation from '@components/SeparationLink';

import cardData from './data/CardList.json';
import mediaData from './data/MediaLibrary';
import styles from './styles.module.css';

const price = 'от 1500 руб/месяц';

export default function index() {
  return (
    <Fragment>
      <Intro />
      <MediaLibrary
        className={styles.playlist}
        list={mediaData}
        libraryFor="магазинов и торговых предприятий"
      />
      <Separation />
      <CardList {...cardData} />
      <Pricing price={price} />
      <CTA />
    </Fragment>
  );
}
