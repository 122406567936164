import React from 'react';
import Body from '@sections/Solutions/Shop';
import Layout from '@layouts/business';
import SEO from '@components/seo';

export default () => (
  <Layout>
    <SEO
      title="Музыка для магазинов | IMstream"
      description="Создадим неповторимую музыкальную атмосферу, специально для вашего магазина. Полностью лицензионный музыкальный каталог."
    />
    <Body />
  </Layout>
);
