import { Store1, Store2, Store3 } from '../images';

const data = [
  {
    src: 'https://storage.yandexcloud.net/imstream/stores_1.mp3',
    Image: Store1,
  },
  {
    src: 'https://storage.yandexcloud.net/imstream/stores_2.mp3',
    Image: Store2,
  },
  {
    src: 'https://storage.yandexcloud.net/imstream/stores_3.mp3',
    Image: Store3,
  },
];

export default data;
